import AgoraRTC from 'agora-rtc-sdk-ng'


const AgoraAppId = '90ad29ca613442a091e8e98967e02a71'
//设置日志输出等级
AgoraRTC.setLogLevel(3)

export {
    AgoraAppId,
    AgoraRTC
}
